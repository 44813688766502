import React, { useEffect, useLayoutEffect, useRef, useState } from "react";

import PropTypes from "prop-types";

import {RiFullscreenExitLine, RiFullscreenLine} from "react-icons/ri";

import IconButton from "@material-ui/core/IconButton";
import Button from "@material-ui/core/Button";
import Tooltip from '@material-ui/core/Tooltip';
import Slider from "@material-ui/core/Slider";

import PlayArrowIcon from "@material-ui/icons/PlayArrow";
import PauseIcon from '@material-ui/icons/Pause';
import ReplayIcon from '@material-ui/icons/Replay';
import Zoom from '@material-ui/core/Zoom';

import FullscreenIcon from '@material-ui/icons/Fullscreen';
import FullscreenExitIcon from '@material-ui/icons/FullscreenExit';

import VolumeUpIcon from '@material-ui/icons/VolumeUp';
import VolumeDownIcon from '@material-ui/icons/VolumeDown';
import VolumeMuteIcon from '@material-ui/icons/VolumeMute';
import VolumeOffIcon from '@material-ui/icons/VolumeOff';

import SpeedIcon from '@material-ui/icons/Speed';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';

import Replay5Icon from '@material-ui/icons/Replay5';
import Forward5Icon from '@material-ui/icons/Forward5';

import PictureInPictureAltIcon from '@material-ui/icons/PictureInPictureAlt';

import SkipNextIcon from '@material-ui/icons/SkipNext';
import SkipPreviousIcon from '@material-ui/icons/SkipPrevious';

import "../../css/video.css"
import TimeString from "./TimeString";
import { CircularProgress } from "@material-ui/core";
import { Link, useHistory } from "react-router-dom";

const Video = props => {

    const {
        ...other
    } = props;

    const [playing, SetPlay] = useState(false);
    const first_playing_update = useRef(true);

    const [is_fullscreen, SetFullScreen] = useState(false);
    
    // const [current_time, SetCurrentTime] = useState(0);
    const [max_time, SetMaxTime] = useState(0);
    // const [watch_time, SetWatchTime] = useState(0);
    const watched = useRef(false);

    const [mouse_hover_handler, SetHoverHandler] = useState(undefined);
    const [hide_controls, SetHideControls] = useState(false);

    const [is_abstract_icon_action, SetAbstractIconAction] = useState(false);

    const [show_speeds, ShowSpeeds] = useState(false);
    const [speed, SetSpeed] = useState(1);

    const video_container = useRef();
    const video_player = useRef();

    const history = useHistory();

    const Clamp = (num, min, max) => {
        return Math.min(Math.max(num, min), max);
    };

    const Volume = (volume = 0) => {
        if (video_player.current === undefined) return;
        video_player.current.volume = parseFloat(volume / 100);
    };

    const ChangeSeekTime = (value) => {
        try {
            // console.log(video_player.current.currentTime);

            value = Clamp(value, 0, max_time);
            // console.log('Value '+ value);
            video_player.current && (video_player.current.currentTime = value);

            // console.log(video_player.current.currentTime);

        } catch(err) {
            console.error(err);
        }
    };

    const HandlePlayButton = () => {
        if (video_player.current && video_player.current.currentTime == max_time) {
            ChangeSeekTime(0);
            SetPlay(true);
            video_player.current.play();
        }
        else SetPlay(!playing);
    };

    const VideoContainerMouseMove = () => {//função para fazer os controles desaparecerem depois de um tempo quando o usuário estiver com o mouse em cima do container de vídeo
        clearTimeout(mouse_hover_handler);
        SetHoverHandler(undefined);

        SetHideControls(false);

        let timeout = setTimeout(() => {
            SetHideControls(true);
        }, 2500);

        SetHoverHandler(timeout);
    }

    const VideoContainerMouseLeave = () => {
        clearTimeout(mouse_hover_handler);
        SetHoverHandler(undefined);
        SetHideControls(true);
    }

    const SpeedButtonClick = (e) => {
        ShowSpeeds(true);
    }

    const TogglePictureMode = () => {
        video_player.current.requestPictureInPicture();
    }

    const TimeUpdated = () => {
        if (video_player.current && video_player.current.duration != max_time) SetMaxTime(video_player.current.duration);
        ((props.CurrentTime && video_player.current && playing) && props.CurrentTime(video_player.current.currentTime));
        // console.log('chamou2',video_player.current.duration)

    }

    useEffect(() => {
        if (playing) video_player.current.play();
        else {
            video_player.current.pause();
            if (!first_playing_update.current) {
                (props.OnPause && props.OnPause());
                (props.CurrentTime && video_player.current && props.CurrentTime(video_player.current.currentTime));
            }
            first_playing_update.current = false;
        }
    }, [playing]);

    useEffect(() => {
        let vc = video_container.current;
        if (is_fullscreen) {
            if (vc.requestFullscreen) vc.requestFullscreen().catch(err => Promise.resolve(err));
            else if (vc.msRequestFullscreen) vc.msRequestFullscreen().catch(err => Promise.resolve(err));
            else if (vc.mozRequestFullScreen) vc.mozRequestFullScreen().catch(err => Promise.resolve(err));
            else if (vc.webkitRequestFullScreen) vc.webkitRequestFullScreen().catch(err => Promise.resolve(err));
        } else {
            if (document.exitFullscreen) document.exitFullscreen().catch(err => Promise.resolve(err));
            else if (document.msExitFullscreen) document.msExitFullScreen().catch(err => Promise.resolve(err));
            else if (document.mozExitFullscreen) document.mozExitFullScreen().catch(err => Promise.resolve(err));
            else if (document.webkitExitFullscreen) document.webkitExitFullScreen().catch(err => Promise.resolve(err));
        }
    }, [is_fullscreen]);

    useEffect(() => {
        video_player.current.playbackRate = Clamp(speed, 0.5, 1.5);
    }, [speed]);

    useEffect(() => {
        if (props.start_at === null) return;
        // SetCurrentTime(props.start_at);
        video_player.current && (video_player.current.currentTime = props.start_at);
    }, [props.start_at]);

    useEffect(() => {
        document.addEventListener("fullscreenchange", () => {
            if (document.fullscreenElement === null) SetFullScreen(false);
            else SetFullScreen(true);
        });
    }, []);
    
    const watch_time = useRef();
    /*Update prop methods*/

    useEffect(() => {
        // return;
        const timer = playing && setInterval(() => {
            // SetWatchTime(watch_time + 1 * speed);
            if(watch_time.current==null){
                watch_time.current=0;
            }
            watch_time.current=watch_time.current + 1 * speed
            console.log("tempo visto => " + watch_time.current);
            (props.WatchTime && props.WatchTime(watch_time.current));
            if (props.OnWatched && !watched.current) {
                // console.log(video_player.current);
                // console.log(video_player.current.currentTime >= max_time - 10);
                // console.log(watch_time >= max_time - 10);
                // if (video_player.current && video_player.current.currentTime >= max_time - 10 ) {
                if ((watch_time.current >= max_time - 40) && (video_player.current && video_player.current.currentTime >= max_time - 40)) {
                    props.OnWatched();
                    watched.current = true;
                }
            }

            if ((video_player.current && video_player.current.currentTime >= max_time)) {
                SetPlay(false);
                let currentInfos = {
                    videoLength: max_time,
                    watched: watched.current,
                    watchTime: watch_time.current
                }
                props.OnVideoEnded && props.OnVideoEnded(currentInfos);
            }
        }, 1000);
        return () => clearInterval(timer);
    }, [watch_time.current, playing]);
    
    useEffect(() => {
        watched.current = false;
        watch_time.current=0;
        // SetWatchTime(0);
        video_player.current && (video_player.current.currentTime = props.start_at);
        SetPlay(false);
    }, [props.src]);

    useEffect(() => {
        console.log('last',props.lastTotalWatchTime)
        if(props.lastTotalWatchTime != 0) {
        watch_time.current= props.lastTotalWatchTime;
    }
}, [props.lastTotalWatchTime])

    useEffect(() => {
        SetMaxTime(video_player.current.duration);
    }, [video_player.current && video_player.current.duration]);

    return(
        <div 
            className={"video-container" + (hide_controls ? " nocursor" : "")} 
            ref={video_container} 
            onMouseMove={VideoContainerMouseMove}
            onMouseLeave={VideoContainerMouseLeave}
            style={{position: "relative"}}
            {...other}
        >
            <div 
                className={"playing-abstract-icon" + (is_abstract_icon_action ? " abstract-icon-animation" : "")}
                onAnimationEnd={() => SetAbstractIconAction(false)}
            >
                {(() => {
                    if (playing) return <PlayArrowIcon/>;
                    else return <PauseIcon/>
                })()}
            </div>
            <div
                id="loading-screen"
                style={{
                    width: "100%",
                    height: "100%",
                    position: "absolute"
                }}
                className="flex jcc align-center"
            >
                <CircularProgress
                    className={video_player.current ? (video_player.current.readyState === 4 ? "hide" : "") : ""}
                />
            </div>
            
            <video 
                src={props.src} 
                className="video-player" 
                id='video_tag'
                poster={props.url_cover??''}
                onError={(e)=>{
                    // alert(`Error ${e.target.error.code}; details: ${e.target.error.message}`)
                }}
                ref={video_player} 
                onClick={() => {
                    SetPlay(!playing);
                    SetAbstractIconAction(true);
                }}
                onDoubleClick={() => SetFullScreen(!is_fullscreen)}
                onTimeUpdate={() => TimeUpdated()}
            />

            <div className={"video-controls flex fdcolumn" + (hide_controls ? "" : " video-controls-hover")}>
                <div className="top flex jcc">
                    <Slider 
                        style={{width: "95%", padding: "5px", color: props.TrailColor}}
                        className="seek-bar"
                        value={video_player.current ? parseFloat(video_player.current.currentTime) : 0}
                        max={max_time}
                        step={0.1}
                        onChange={(e, value) => {
                            // console.log(e,value);
                            ChangeSeekTime(value)
                        }}
                    />
                </div>
                <div className="bottom flex fdrow align-center jcsb">
                    <div className="left inline-flex align-center">
                        <Link
                            className={"remove-a" + (props.previousVideoLink !== null ? props.previousVideoLink : " hide")}
                            to={props.previousVideoLink ?? ''}
                        >
                            <IconButton
                                style={{color: "white", padding: "1px", width: "1em", height: "1em", marginLeft: "5px"}}
                            >
                                <SkipPreviousIcon/>
                            </IconButton>
                        </Link>
                        <Tooltip
                            title={(() => {
                                if (video_player.current && video_player.current.currentTime == max_time) return "Replay";
                                else {
                                    if (playing) return "Pause";
                                    else return "Play"
                                }
                            })()}
                            placement="top"
                            arrow
                            TransitionComponent={Zoom}
                        >
                            <IconButton style={{color: "white"}} onClick={() => HandlePlayButton()}>
                                {
                                    (() => {
                                        if (video_player.current && video_player.current.currentTime != max_time) {
                                            if (playing) return <PauseIcon/>;
                                            else return <PlayArrowIcon/>;
                                        }
                                        else return <ReplayIcon/>;
                                    })()
                                }
                            </IconButton>
                        </Tooltip>
                        <IconButton
                            style={{color: "white", padding: "1px", width: "1em", height: "1em", marginLeft: "5px"}}
                            className={props.nextVideoLink ? "" : " hide"}
                            onClick={() => history.push(props.nextVideoLink)}
                        >
                            <SkipNextIcon/>
                        </IconButton>
                        <div className="audio-container flex jcc align-center">
                            <Tooltip
                                title={(() => {
                                    if (video_player.current && video_player.current.volume == 0) return "Desmutar";
                                    else return "Mutar";
                                })()}
                                placement="top"
                                arrow
                                TransitionComponent={Zoom}
                            >
                                <IconButton 
                                    style={{color: "white"}} 
                                    className="audio-control"
                                    onClick={() => {
                                        if (video_player.current && video_player.current.volume == 0) Volume(20);
                                        else Volume(0);
                                    }}
                                >
                                    {
                                        (() => {
                                            if (video_player.current && video_player.current.volume == 0) return <VolumeOffIcon/>
                                            else if (video_player.current && video_player.current.volume > 0 && video_player.current.volume <= .2) return <VolumeMuteIcon/>
                                            else if (video_player.current && video_player.current.volume > .2 && video_player.current.volume <= .6) return <VolumeDownIcon/>
                                            else if (video_player.current && video_player.current.volume > .6) return <VolumeUpIcon/>
                                        })()
                                    }
                                </IconButton>
                            </Tooltip>
                            <Tooltip
                                title={`${video_player.current ? parseInt(video_player.current.volume * 100) : 0}/100`}
                                placement="top"
                                arrow
                                TransitionComponent={Zoom}
                            >
                                <Slider 
                                    value={video_player.current ? video_player.current.volume * 100 : 0} 
                                    onChange={(e, value) => {    
                                        Volume(value);
                                    }} 
                                    step={0.1}
                                    className="audio-slider"
                                    style={{color: props.VolumeTrailColor, marginRight: "5px"}}
                                />
                            </Tooltip>
                        </div>
                        <div className="timer-control flex jcc align-center">
                            <TimeString seconds={video_player.current ? video_player.current.currentTime : 0}/>/<TimeString seconds={isNaN(max_time) ? 0 : max_time}/>
                        </div>
                        <div className="jump-buttons flex jcc align-center d-none d-md-block">
                            <Tooltip
                                title="Retroceder 5 segundos"
                                placement="top"
                                arrow
                                TransitionComponent={Zoom}
                            >
                                <IconButton 
                                    style={{color: "white", padding: "1px", width: "1em", height: "1em", marginRight: "5px"}}
                                    onClick={() => ChangeSeekTime(video_player.current ? (video_player.current.currentTime - 5) : 0)}
                                >
                                    <Replay5Icon/>
                                </IconButton>
                            </Tooltip>
                            <Tooltip
                                title="Avançar 5 segundos"
                                placement="top"
                                arrow
                                TransitionComponent={Zoom}
                            >
                                <IconButton 
                                    style={{color: "white", padding: "1px", width: "1em", height: "1em", marginLeft: "5px"}}
                                    onClick={() => ChangeSeekTime(video_player.current ? (video_player.current.currentTime + 5) : 0)}
                                >
                                    <Forward5Icon/>
                                </IconButton>
                            </Tooltip>
                        </div>
                    </div>
                    <div className="right inline-flex">
                        <div className="d-none d-lg-block">
                            <Tooltip
                                title="Miniplayer"
                                placement="top"
                                arrow
                                TransitionComponent={Zoom}
                            >
                                <IconButton style={{color: "white"}} onClick={TogglePictureMode}>
                                    <PictureInPictureAltIcon/>
                                </IconButton>
                            </Tooltip>
                        </div>
                        <div className="speed-control d-none d-lg-block">
                            <Tooltip
                                title="Alterar velocidade"
                                placement="top"
                                arrow
                                TransitionComponent={Zoom}
                            >
                                <IconButton style={{color: "white"}} onClick={() => {
                                    SpeedButtonClick();
                                    SetFullScreen(false);
                                }}>
                                    <SpeedIcon/>
                                </IconButton>
                            </Tooltip>
                            <Dialog
                                open={show_speeds}
                                onClose={() => ShowSpeeds(false)}
                            >
                                <DialogTitle>Escolha uma velocidade</DialogTitle>
                                {
                                    [0.25, 0.5, 0.75, 1, 1.25, 1.5, 2].reverse().map((value, index) => {
                                        return <Button 
                                                    variant={(speed == value ? "contained" : null)}
                                                    color="primary" 
                                                    key={index}
                                                    onClick={() => {
                                                        SetSpeed(value);
                                                        ShowSpeeds(false);
                                                }}>{value == 1 ? `Normal` : `${value}x`}</Button>
                                    })
                                }
                            </Dialog>
                        </div>
                        <Tooltip
                            title={(() => {
                                if (is_fullscreen) return "Sair da tela cheia"
                                else return "Tela cheia"
                            })()}
                            placement="top"
                            arrow
                            TransitionComponent={Zoom}
                        >
                            <IconButton style={{color: "white"}} onClick={() => SetFullScreen(!is_fullscreen)}>
                                {(is_fullscreen ? <FullscreenExitIcon/> : <FullscreenIcon/>)}
                            </IconButton>
                        </Tooltip>
                    </div>
                </div>
            </div>
        </div>
    );
}

Video.defaultProps = {
    start_at: 0
}

export default Video;