import React from "react";

import { API_URL, URL } from "../../../../variables";
import { connect } from "react-redux";
import { logout } from "../../../../actions/AppActions";
import { Redirect } from "react-router-dom";
import SweetAlert from "react-bootstrap-sweetalert";
import Request from "../../../../Request";
import { BsDownload } from "react-icons/bs";
import { BsX, BsCheck } from "react-icons/bs";

import { Button } from "@material-ui/core";
import DefaultButton from "../../../ReusableComponents/DefaultButton";
import { MdCached } from "react-icons/md";
import { RiArrowGoBackLine } from "react-icons/ri";

import Feedback from "../../Courses/Users/Feedback/";
import ObjectEqual from "../../../../Auxiliar/CompareObject";

function getShuffledArr(arr) {
  return [...arr].map((_, i, arrCopy) => {
    var rand = i + Math.floor(Math.random() * (arrCopy.length - i));
    [arrCopy[rand], arrCopy[i]] = [arrCopy[i], arrCopy[rand]];
    return arrCopy[i];
  });
}

class Prova extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      msg: "",
      redirect: false,
      path: "/processo",
      prova: {},
      questoes: [],
      curso: {},
      respostas: {},
      savedAnswersFlag: {},
      resultado: {},
      loading_resposta: {},
      show_success: false,
      show_error: false,
      msg_erro: "",
      loading_screen: true,
      loading_finish: false,
      user_course: {},
      flag: "",
      show_warning: false,
      lesson_id: "",
      video_id: "",
      completed_course: false,
      user_in: false,
      questoes_respondidas:[]
    };
    this.questoesRef = {};
    // this.videoRef=null;
    this.teste_certificate = React.createRef();
  }

  async buscar_questoes() {
    this.setState({
      questoes: [],
      loading_screen: false,
      respostas: {},
      loading_screen: true,
      flag: "",
    });

    let response = await Request.Get(
      `courses/avaliacao/${this.props.match.params.curso_id}`
    );
    console.log(response);
    if (response === undefined) return;
    if (response["message"] == "Unauthenticated.") {
      this.props.logout();
      return;
    }
    if (response["status"] == false) {
      // response["user_in"] === false && this.props.history.push({
      //     pathname: "/courses/course/" + this.props.match.params.curso_id,
      // });
      console.log(response);
      this.setState({
        loading_screen: false,
        completed_course: response["completed_exam"],
        user_in: response["user_in"],
      });
      return;
    }
    let respostas = {};
    for (let i = 0; i < response.questoes.length; i++) {
      if (response.questoes[i].resposta != null) {
        respostas[response.questoes[i].id] = response.questoes[i].resposta;
      }
    }
    // response.questoes = getShuffledArr(response.questoes);
    this.setState({
      user_in: response.user_course,
      lesson_id: response.lesson_id,
      video_id: response.video_id,
      questoes: response.questoes,
      curso: response.curso,
      loading_screen: false,
      respostas,
      savedAnswersFlag: respostas,
      user_course: response.user_course,
      completed_course: response.user_course.completed_course,
      questoes_respondidas:response.questoes_respondidas,
    });
  }

  async componentDidMount() {
    this.buscar_questoes();
    window.onbeforeunload = () => {
      if (!ObjectEqual(this.state.respostas, this.state.savedAnswersFlag))
        return "Tem certeza que deseja sair?";
    };
  }

  componentDidUpdate() {
    console.log(ObjectEqual(this.state.respostas, this.state.savedAnswersFlag));
  }

  async encerrar_avaliacao(curso_id) {
    this.setState({ loading_finish: true });
    let response = await Request.Post(
      `courses/avaliacao/finalizar_avaliacao`,
      JSON.stringify({
        curso_id: curso_id,
      }),
      { "Content-Type": "application/json" }
    );
    if (response === undefined) return;
    if (response["message"] == "Unauthenticated.") {
      this.props.logout();
      return;
    }
    if (response["errors"]) {
      this.setState({
        show_error: true,
        msg_erro: response["errors"][Object.keys(response["errors"])][0],
        loading_finish: false,
      });
    } else {
      // let provas = [...this.props.provas];
      // provas[this.props.indice].respostas[questao_id] = this.state.respostas[questao_id];
      // this.props.mudarProvas(provas);

      this.setState({
        loading_finish: false,
        flag: response.flag,
        user_course: response.user_course,
        questoes_respondidas:response.questoes_respondidas
      });
    }
  }

  async salvar_resposta(questao_id, resposta_id, index) {
    let response = await Request.Post(
      `courses/avaliacao/salvar_resposta`,
      JSON.stringify({
        curso_id: this.state.curso.id,
        questao_id,
        alternativa_id: resposta_id,
      }),
      { "Content-Type": "application/json" }
    );
    if (response === undefined) return;
    if (response["message"] == "Unauthenticated.") {
      this.props.logout();
      return;
    }

    let loading_resposta = { ...this.state.loading_resposta };
    loading_resposta[questao_id] = false;

    if (response["errors"]) {
      this.setState({
        show_error: true,
        msg_erro: response["errors"][Object.keys(response["errors"])][0],
        loading_resposta,
      });
    } else {
      // let provas = [...this.props.provas];
      // provas[this.props.indice].respostas[questao_id] = this.state.respostas[questao_id];
      // this.props.mudarProvas(provas);
      let questoes = [...this.state.questoes];
      questoes[index].resposta = resposta_id;

      this.setState({
        loading_resposta,
        show_success: true,
        questoes,
        savedAnswersFlag: this.state.respostas,
      });
    }
  }

  render() {
    return (
      <div className="card">
        <div className="card-body">
          <div className="container-fluid">
            {this.state.loading_screen == false &&
              this.state.questoes.length == 0 &&
              this.state.completed_course === true && (
                <div className="row mt-3">
                  <div className="col-12">
                    <div>
                      <DefaultButton
                        icon={<RiArrowGoBackLine />}
                        text="Voltar"
                        search={
                          this.state.video_id != null &&
                          this.state.lesson_id != null
                            ? `?video=${this.state.video_id}&lesson=${this.state.lesson_id}`
                            : ""
                        }
                        bg="secondary"
                        to={
                          "/courses/course/" + this.props.match.params.curso_id
                        }
                      />
                    </div>
                    <h5
                      className="mt-0 header-title"
                      style={{ fontSize: "1.5rem", textAlign: "center" }}
                    >
                      Avaliação {this.state.curso.name}
                    </h5>
                    <hr />
                  </div>
                  <div className="col-12">
                    <p style={{ textAlign: "center" }}>
                      Nenhuma questão cadastrada
                    </p>
                  </div>
                </div>
              )}

            <div
              className="col-12"
              hidden={
                this.state.loading_screen ||
                this.state.completed_course ||
                !this.state.user_in
              }
            >
              <p
                style={{
                  textAlign: "center",
                  fontSize: "20pt",
                }}
              >
                Para realizar a avaliação, complete todo o curso primeiro!
              </p>
              <DefaultButton
                icon={<RiArrowGoBackLine />}
                text="Voltar"
                search={
                  this.state.video_id != null && this.state.lesson_id != null
                    ? `?video=${this.state.video_id}&lesson=${this.state.lesson_id}`
                    : ""
                }
                bg="secondary"
                to={"/courses/course/" + this.props.match.params.curso_id}
              />
            </div>

            <div
              className="col-12"
              hidden={this.state.loading_screen || this.state.user_in}
            >
              <p
                style={{
                  textAlign: "center",
                  fontSize: "20pt",
                }}
              >
                Você não está inserido no curso para realizar a avaliação!
              </p>
              <DefaultButton
                icon={<RiArrowGoBackLine />}
                text="Voltar"
                search={
                  this.state.video_id != null && this.state.lesson_id != null
                    ? `?video=${this.state.video_id}&lesson=${this.state.lesson_id}`
                    : ""
                }
                bg="secondary"
                to={"/courses/course/" + this.props.match.params.curso_id}
              />
            </div>

            {this.state.loading_screen == false &&
              this.state.questoes.length > 0 &&
              this.state.flag == "" &&
              this.state.user_course.completed_exam == false && (
                <div className="row mt-3">
                  {/* {this.state.redirect == true && <Redirect to={this.state.path} />} */}

                  <div className="col-12">
                    <div className="flex fdrow jcsb">
                      <div>
                        <DefaultButton
                          icon={<RiArrowGoBackLine />}
                          text="Voltar"
                          search={
                            this.state.video_id != null &&
                            this.state.lesson_id != null
                              ? `?video=${this.state.video_id}&lesson=${this.state.lesson_id}`
                              : ""
                          }
                          bg="secondary"
                          to={
                            "/courses/course/" +
                            this.props.match.params.curso_id
                          }
                        />
                      </div>
                      <h5
                        className="mt-0 header-title"
                        style={{ fontSize: "1.5rem" }}
                      >
                        Avaliação {this.state.curso.name}
                      </h5>
                      {this.state.loading_finish == false && (
                        <DefaultButton
                          onClick={() => {
                            this.setState({ show_warning: true });
                          }}
                          type="button"
                          id="finalizar"
                          // className="btn btn-success btn-lg waves-effect waves-light mt-3"
                          style={{
                            background: "indianred",
                            border: "1px solid indianred",
                          }}
                          text="Encerrar e entregar a avaliação"
                        >
                          Encerrar e entregar a avaliação
                        </DefaultButton>
                      )}
                    </div>
                    <hr />
                    <h6
                      className="mt-0 header-title"
                      style={{ textAlign: "center", marginBottom: "1.5rem" }}
                    >
                      Quais questões foram respondidas?
                    </h6>
                    <div className="row">
                      {this.state.questoes.map((item, id) => (
                        <div
                          key={id}
                          className="col-2"
                          style={{ marginTop: "10px" }}
                        >
                          <a
                            onClick={() => {
                              window.scrollTo(
                                0,
                                this.questoesRef[item.id].offsetTop + 200
                              );
                            }}
                            style={{
                              cursor: "pointer",
                              color: "black",
                              textDecoration: "none",
                            }}
                            className="smooth-goto"
                          >
                            {item.resposta == null && (
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                  alignItems: "center",
                                  width: 65,
                                }}
                              >
                                <b>{id + 1})</b>
                                <div
                                  style={{
                                    border: "1px solid grey",
                                    width: "30px",
                                    height: "30px",
                                    textAlign: "center",
                                    marginLeft: "0.5rem",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    display: "flex",
                                  }}
                                >
                                  {" "}
                                  -{" "}
                                </div>
                              </div>
                            )}
                            {item.resposta != null && (
                              <div
                                style={{
                                  display: "flex",
                                  width: 65,
                                  justifyContent: "space-between",
                                  alignItems: "center",
                                }}
                              >
                                <b>{id + 1})</b>
                                <div
                                  style={{
                                    border: "1px solid grey",
                                    width: "30px",
                                    height: "30px",
                                    textAlign: "center",
                                    marginLeft: "0.5rem",
                                    fontWeight: "bold",
                                    color: "black",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    display: "flex",
                                  }}
                                >
                                  {" "}
                                  {Object.keys(item.alternativas).map(
                                    (item2, id2) => {
                                      if (
                                        item.resposta ==
                                        item.alternativas[item2].id
                                      ) {
                                        return item2;
                                      }
                                    }
                                  )}{" "}
                                </div>
                              </div>
                            )}
                          </a>
                        </div>
                      ))}
                    </div>
                  </div>
                  <div className="col-12">
                    {this.state.questoes.map((item, id) => (
                      <div
                        ref={(ref) => (this.questoesRef[item.id] = ref)}
                        key={id}
                      >
                        <hr style={{ borderTop: "1px solid rgba(0,0,0,.2)" }} />

                        {/* <h6 className="mt-0 header-title" style={{ textAlign: 'right' }}>
                                        {item.categoria.name}</h6> */}

                        <div className="row" style={{ fontWeight: "normal" }}>
                          <div className="col-4 col-sm-1">
                            <h5
                              style={{
                                textAlign: "justify",
                                color: "black",
                                marginTop: 0,
                              }}
                            >
                              <b>{id + 1})</b>
                            </h5>
                          </div>
                          <div className="col-12 col-sm-11">
                            <div
                              dangerouslySetInnerHTML={{
                                __html: item.enunciado,
                              }}
                            ></div>
                            <form>
                              {Object.keys(item.alternativas).map(
                                (alternativa, id2) => (
                                  <div
                                    key={id2}
                                    style={{
                                      color: "black",
                                      textAlign: "justify",
                                    }}
                                    className="row"
                                  >
                                    <div
                                      className="col-4 pt-3 pb-3"
                                      style={{
                                        display: "flex",
                                        alignItems: "center",
                                      }}
                                    >
                                      <input
                                        onChange={() => {
                                          // let questoes = [...this.state.questoes];
                                          // questoes[id].resposta = item.alternativas[alternativa].id;
                                          let respostas = {
                                            ...this.state.respostas,
                                          };
                                          respostas[item.id] =
                                            item.alternativas[alternativa].id;
                                          this.setState({ respostas });

                                          let loading_resposta = {
                                            ...this.state.loading_resposta,
                                          };
                                          loading_resposta[item.id] = true;
                                          this.setState({ loading_resposta });
                                          this.salvar_resposta(
                                            item.id,
                                            item.alternativas[alternativa].id,
                                            id
                                          );
                                        }}
                                        className="form-check-input"
                                        type="radio"
                                        name="resposta_id"
                                        // value="{{$alternativa->id}}"
                                        style={{
                                          position: "relative",
                                          marginLeft: 0,
                                          marginTop: 0,
                                          marginRight: "10px",
                                        }}
                                        checked={
                                          this.state.respostas[item.id] ==
                                          item.alternativas[alternativa].id
                                        }
                                      />
                                      {alternativa}
                                    </div>
                                    <div
                                      dangerouslySetInnerHTML={{
                                        __html:
                                          item.alternativas[alternativa].texto,
                                      }}
                                      className="col-8 pt-3 pb-3"
                                      style={{}}
                                    ></div>
                                  </div>
                                )
                              )}
                            </form>
                          </div>

                          {/* <div className="col-12 d-flex justify-content-end mt-2">
                                                {this.state.loading_resposta[item.id] == true && <div className="spinner-border text-primary" role="status">
                                                </div>}
                                                {this.state.loading_resposta[item.id] != true && 
                                                <DefaultButton
                                                    onClick={() => {
                                                        if (this.state.respostas[item.id] != undefined) {
                                                            let loading_resposta = { ...this.state.loading_resposta };
                                                            loading_resposta[item.id] = true;
                                                            this.setState({ loading_resposta });
                                                            this.salvar_resposta(item.id, this.state.respostas[item.id], id);
                                                        }
                                                        else {
                                                            this.setState({ show_error: true, msg_erro: 'Selecione uma alternativa desta questão antes de salvar a resposta' });
                                                        }

                                                    }}
                                                    type="button"
                                                    bg="confirm"
                                                    // className="btn btn-success waves-effect waves-light responder"
                                                    text={`Salvar resposta da questão ${id + 1}`}
                                                >
                                                    Salvar resposta da questão {id + 1})
                                                </DefaultButton>}
                                            </div> */}
                        </div>

                        {/* {!!Form::close() !!} */}
                      </div>
                    ))}
                  </div>
                  <SweetAlert
                    success
                    title={"Respondida!"}
                    onConfirm={() => this.setState({ show_success: false })}
                    // onCancel={this.onCancel.bind(this)}
                    show={this.state.show_success}
                    confirmBtnText="Ok"
                    cancelBtnText="Cancelar"
                    confirmBtnBsStyle="info"
                    // cancelBtnBsStyle="success"
                    showCancel={false}
                  >
                    Questão respondida com sucesso
                  </SweetAlert>
                  <SweetAlert
                    danger
                    title={"Erro!"}
                    onConfirm={() => this.setState({ show_error: false })}
                    // onCancel={this.onCancel.bind(this)}
                    show={this.state.show_error}
                    confirmBtnText="Ok"
                    cancelBtnText="Cancelar"
                    confirmBtnBsStyle="success"
                    cancelBtnBsStyle="success"
                    showCancel={false}
                  >
                    {this.state.msg_erro}
                  </SweetAlert>

                  <SweetAlert
                    warning
                    title={"Deseja mesmo encerrar a avaliação?"}
                    onConfirm={() => {
                      this.setState({ show_warning: false });
                      this.encerrar_avaliacao(this.state.curso.id);
                    }}
                    onCancel={() => {
                      this.setState({ show_warning: false });
                    }}
                    // onCancel={this.onCancel.bind(this)}
                    show={this.state.show_warning}
                    confirmBtnText="Finalizar"
                    cancelBtnText="Cancelar"
                    confirmBtnBsStyle="success"
                    cancelBtnBsStyle="danger"
                    showCancel={true}
                  >
                    {Object.values(this.state.respostas).length <
                    this.state.questoes.length
                      ? "Existem questões ainda não respondidas"
                      : "Ao encerrar será feita o cálculo do resultado"}
                  </SweetAlert>
                </div>
              )}

            {this.state.loading_screen == false &&
              this.state.flag == "fail_try_again" &&
              this.state.user_course.completed_exam == false && (
                <div className="row mt-3">
                  <div className="col-12">
                    <div style={{ float: "right" }}>
                      <DefaultButton
                        icon={<RiArrowGoBackLine />}
                        text="Voltar"
                        search={
                          this.state.video_id != null &&
                          this.state.lesson_id != null
                            ? `?video=${this.state.video_id}&lesson=${this.state.lesson_id}`
                            : ""
                        }
                        bg="secondary"
                        to={
                          "/courses/course/" + this.props.match.params.curso_id
                        }
                      />
                    </div>
                    <h5
                      className="mt-0 header-title"
                      style={{ fontSize: "1.5rem", textAlign: "center" }}
                    >
                      Avaliação {this.state.curso.name}
                    </h5>
                    <hr />
                  </div>

                  <h5 style={{ textAlign: "center" }}>
                    Infelizmente você não atingiu a pontuação mínima (60%) para
                    a conclusão do curso. Tente realizar a prova novamente.
                  </h5>
                  <br />
                  <h5
                    style={{
                      textAlign: "center",
                      fontWeight: "600",
                      marginTop: 20,
                    }}
                  >
                    Sua Pontuação:
                  </h5>
                  <h5 style={{ textAlign: "center", color: "red" }}>
                    {parseFloat(
                      this.state.user_course.porcengetam_acerto
                    ).toFixed(2)}
                    %
                  </h5>

                  <div className="row">

                                    {this.state.questoes_respondidas.map((item, id) => (
                                        <div key={id} className="col-2" style={{ marginTop: '10px' }}>

                                            <div style={{  color: "black", textDecoration: 'none' }} className="smooth-goto" >
                                                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: 65 }}>
                                                        <b>{id + 1})</b>
                                                        <div style={{ border: '1px solid grey', width: '30px', height: '30px', textAlign: 'center', marginLeft: '0.5rem', justifyContent: 'center', alignItems: 'center', display: 'flex' }}> {item==true?<BsCheck color="green" />:<BsX color="red"/>} </div>
                                                    </div>
                                                

                                            </div>
                                        </div>
                                    ))}



                                </div>

                  <DefaultButton
                    onClick={() => {
                      this.buscar_questoes();
                    }}
                    bg="danger"
                    icon={<MdCached style={{ marginRight: 5 }} />}
                    text="Refazer avaliação"
                    style={{ width: "100%", marginTop: 15 }}
                  />
                </div>
              )}

            {this.state.loading_screen == false &&
              this.state.flag == "fail" &&
              this.state.user_course.completed_exam == false && (
                <div className="row mt-3">
                  <div className="col-12">
                    <div style={{ float: "right" }}>
                      <DefaultButton
                        icon={<RiArrowGoBackLine />}
                        text="Voltar"
                        search={
                          this.state.video_id != null &&
                          this.state.lesson_id != null
                            ? `?video=${this.state.video_id}&lesson=${this.state.lesson_id}`
                            : ""
                        }
                        bg="secondary"
                        to={
                          "/courses/course/" + this.props.match.params.curso_id
                        }
                      />
                    </div>
                    <h5
                      className="mt-0 header-title"
                      style={{ fontSize: "1.5rem", textAlign: "center" }}
                    >
                      Avaliação {this.state.curso.name}
                    </h5>
                    <hr />
                  </div>

                  <h5 style={{ textAlign: "center" }}>
                    Infelizmente você não atingiu a pontuação mínima (60%)
                    necessária para a conclusão do curso. Assista os vídeos e
                    tente novamente a realizar a prova.
                  </h5>
                  <br />
                  <h5
                    style={{
                      textAlign: "center",
                      fontWeight: "600",
                      marginTop: 20,
                    }}
                  >
                    Sua Pontuação:
                  </h5>
                  <h5 style={{ textAlign: "center", color: "red" }}>
                    {parseFloat(
                      this.state.user_course.porcengetam_acerto
                    ).toFixed(2)}
                    %
                  </h5>

                  <div className="row">

                                    {this.state.questoes_respondidas.map((item, id) => (
                                        <div key={id} className="col-2" style={{ marginTop: '10px' }}>

                                            <div style={{  color: "black", textDecoration: 'none' }} className="smooth-goto" >
                                                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: 65 }}>
                                                        <b>{id + 1})</b>
                                                        <div style={{ border: '1px solid grey', width: '30px', height: '30px', textAlign: 'center', marginLeft: '0.5rem', justifyContent: 'center', alignItems: 'center', display: 'flex' }}> {item==true?<BsCheck color="green" />:<BsX color="red"/>} </div>
                                                    </div>
                                                

                                            </div>
                                        </div>
                                    ))}



                                </div>

                  <DefaultButton
                    onClick={() => {
                      this.props.history.push({
                        pathname:
                          "/courses/course/" + this.props.match.params.curso_id,
                      });
                    }}
                    bg="danger"
                    icon={<MdCached style={{ marginRight: 5 }} />}
                    text="Refazer curso"
                    style={{ width: "100%", marginTop: 15 }}
                  />
                </div>
              )}

            {this.state.loading_screen == false &&
              this.state.user_course.completed_exam == true && (
                <div className="row mt-3">
                  <div className="col-12">
                    <div style={{ float: "right" }}>
                      <DefaultButton
                        icon={<RiArrowGoBackLine />}
                        text="Voltar"
                        search={
                          this.state.video_id != null &&
                          this.state.lesson_id != null
                            ? `?video=${this.state.video_id}&lesson=${this.state.lesson_id}`
                            : ""
                        }
                        bg="secondary"
                        to={
                          "/courses/course/" + this.props.match.params.curso_id
                        }
                      />
                    </div>
                    <h5
                      className="mt-0 header-title"
                      style={{ fontSize: "1.5rem", textAlign: "center" }}
                    >
                      Avaliação {this.state.curso.name}
                    </h5>
                    <hr />
                  </div>

                  <h5 style={{ textAlign: "center" }}>
                    Parabéns, você foi aprovado com{" "}
                    {parseFloat(
                      this.state.user_course.porcengetam_acerto
                    ).toFixed(2)}
                    % de acertos.
                  </h5>
                  <div className="row">

{this.state.questoes_respondidas.map((item, id) => (
    <div key={id} className="col-2" style={{ marginTop: '10px' }}>

        <div style={{  color: "black", textDecoration: 'none' }} className="smooth-goto" >
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: 65 }}>
                    <b>{id + 1})</b>
                    <div style={{ border: '1px solid grey', width: '30px', height: '30px', textAlign: 'center', marginLeft: '0.5rem', justifyContent: 'center', alignItems: 'center', display: 'flex' }}> {item==true?<BsCheck color="green" />:<BsX color="red"/>} </div>
                </div>
            

        </div>
    </div>
))}



</div>
                  <br />
                </div>
              )}

            {this.state.loading_screen == true && (
              <div className="row">
                {" "}
                <div
                  className="col-12 d-flex justify-content-center align-items-center"
                  style={{ marginTop: "2rem" }}
                >
                  <div
                    className="spinner-border text-primary"
                    role="status"
                  ></div>
                </div>
              </div>
            )}
          </div>
          <br />
          {(
            this.state.loading_screen === false &&
            this.state.user_course.completed_exam == true
          ) && (
            <Feedback
              course_id={this.props.match.params.curso_id}
              user_course={this.state.user_course}
            />
          )}
        </div>
      </div>
    );
  }

  onConfirm() {}

  onCancel() {}
}

const mapsStateToProps = (state) => ({
  token: state.AppReducer.token,
  faculdade: state.AppReducer.faculdade,
  processo: state.AppReducer.processo,
  user: state.AppReducer.user,
  redacoes: state.AppReducer.redacoes,
  provas: state.AppReducer.provas,
});

export default connect(mapsStateToProps, { logout })(Prova);
