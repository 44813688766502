import { Snackbar } from "@material-ui/core";
import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import Request from "../../../../Request";
import { API_URL, URL } from "../../../../variables";
import Video from "../../../ReusableComponents/Video";

const UsersLessonPage = ({videoId, lessonId,videoPath, onCompleteSync, previousVideoLink, nextVideoLink}) => {
    console.log(videoPath)
    const [sendingData, SetSendingData] = useState(false);
    const [startVideoAt, SetStartVideoAt] = useState(0);
    const [url, setUrl] = useState('');
    const [url_cover, setUrlCover] = useState(null);



    const [lastWatchTime, SetLastWatchTime] = useState(0);
    // const [watchTime, SetWatchTime] = useState(0);
    const watchTime = useRef();
    const [snackbar, SetSnackbar] = useState(false);
    const [snackbarMessage, SetSnackbarMessage] = useState("");

    const userId = useSelector(store => store.AppReducer.user.id);

    const last_time = useRef();

    const UpdateWatchTime = async (time) => {
        if(sendingData==true || last_time.current==time){
            return;
        }
        last_time.current=time;
        let token = localStorage.getItem("token");
        if (!token || !lessonId || sendingData) return;
        SetSendingData(true);
        let form = new FormData();
        form.append("user_id", userId);
        form.append("lesson_id", lessonId);
        form.append("video_id", videoId);
        form.append("watch_time", Math.floor(time));
        form.append("total_watch_time", parseFloat(watchTime.current ?? 0));
        console.log('chamou')
        let response = await Request.Post("lessons/progress/watchtime", form, {"Authorization": `Bearer ${token}`});
        SetSendingData(false);
    }

    const SendCompleteSignal = async () => {
        let token = localStorage.getItem("token");
        if (!token) return;

        let form = new FormData();
        form.append("user_id", userId);
        form.append("lesson_id", lessonId);
        form.append("video_id", videoId)
        form.append("completed", true);

        let response = await Request.Post("lessons/progress/watchtime/complete", form, {"Authorization": `Bearer ${token}`});
        onCompleteSync();
    }

    const GetUserLog = async () => {
        let token = localStorage.getItem("token");
        if (!token || !userId || !videoId) return;

        let response = await Request.Get(`lessons/progress/user-log/${userId}/${videoId}`);
        if (!response) return;
        if (response["status"] && response["lesson_progress"]) {
            SetStartVideoAt(response["lesson_progress"]["watch_time"]);
            if (response["lesson_progress"]["total_watch_time"]) SetLastWatchTime(parseFloat(response["lesson_progress"]["total_watch_time"]));
        } else {
            SetStartVideoAt(0);
        }
    }

    const GetVideoLesson = async (video_id) => {
        let token = localStorage.getItem("token");
        if (!token || !userId || !videoId) return;

        let response = await Request.Get(`lessons/video/${videoId}`);
        if (!response) return;
        console.log(response.url)
        setUrl(response.url)
        setUrlCover(response.url_cover)
        GetUserLog();
        UpdateLastWatch();
    }

    const UpdateLastWatch = async () => {
        let token = localStorage.getItem("token");
        if (!token || !userId || !lessonId || !videoId) return;

        let form = new FormData();
        form.append("user_id", userId);
        form.append("lesson_id", lessonId);
        form.append("video_id", videoId);

        let response = await Request.Post(`lessons/progress/watchtime/log`, form, {"Authorization": `Bearer ${token}`});
    }

    useEffect(() => {
        if(videoId){
            GetVideoLesson()
        }
        else{
            // GetUserLog();
            // UpdateLastWatch();
        }
        
    }, [videoId]);

    return (
        <div>
            {url && <Video
                // src={(videoId != "" && videoId !== undefined && videoId) ? API_URL + "lessons/video/" + videoId : ""}
                src={(videoId != "" && videoId !== undefined && videoId && url) ? (URL + url) : ""}
                url_cover={url_cover}

                speeds={[0.25, 0.5, 0.75, 1, 1.25, 1.5, 2]}
                VolumeTrailColor="white"
                start_at={startVideoAt}
                lastTotalWatchTime={lastWatchTime}
                CurrentTime={(time) => {
                    time = ~~time;
                    if (time != 0 && time % 10 == 0) UpdateWatchTime(time);
                }}
                OnWatched={() => {
                    SendCompleteSignal()
                }}
                OnVideoEnded={(x) => {
                    let videoLength = x.videoLength;
                    console.log(watchTime.current + " " + videoLength)
                    if (!(watchTime.current >= videoLength - 20)) {
                        SetSnackbar(true);
                        SetSnackbarMessage("O vídeo terminou, porém você não assistiu o tempo necessário para marcar como visto");
                    }
                }}
                WatchTime={value => {
                    console.log('watch time ',watchTime.current)
                    watchTime.current=value;
                    // SetWatchTime(value);
                }}
                previousVideoLink={previousVideoLink}
                nextVideoLink={nextVideoLink}
                hidden={!videoId}
            />}
            <Snackbar
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                autoHideDuration={10000}
                onClose={() => SetSnackbar(false)}
                open={snackbar}
                message={snackbarMessage}
            />
        </div>
    );
}

export default UsersLessonPage;